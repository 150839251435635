import axios from 'axios'
import moment from 'moment'
import { CALLAPI } from '../middleware/callAPIMiddleware'
import { elasticSearchURL } from '../../config/endpoints'

const FETCH_POSTING = 'FETCH_POSTING'
const FETCH_POSTING_SUCCESS = 'FETCH_POSTING_SUCCESS'
const FETCH_POSTING_FAIL = 'FETCH_POSTING_FAIL'
const RESET_POSTING = 'RESET_POSTING'

export const initialState = {
  posting: {
    title: 'POSITION TITLE WILL DISPLAY HERE',
    description: `
    <p>
      <strong>
        The job description will display in this area.
      </strong>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
      incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
      exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
      irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
      officia deserunt mollit anim id est laborum.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
      incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
      exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
      irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
      officia deserunt mollit anim id est laborum.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
      incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
      exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
      irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
      officia deserunt mollit anim id est laborum.
    </p>`,
    address: {
      city: 'Denver',
      country: 'US',
      postalCode: '12345',
      stateOrProvince: 'CO',
      street1: '1234 G Seminole',
      street2: 'South of Garfield Road',
    },
    clientName: 'Company Name',
    positionType: 'Job Title',
    contractType: 'fullTime',
    shifts: {
      firstShift: true,
      secondShift: true,
      thirdShift: true,
    },
    createdDate: moment().format('YYYY-MM-DD'),
    jobId: 12345123,
    category: 'Some Category',
    compensation: '35,000 - 40,000',
  },
  error: null,
  postingLoading: false,
  postingNotFound: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_POSTING:
      return {
        ...initialState,
      }
    case FETCH_POSTING:
      return {
        ...state,
        postingLoading: true,
        postingNotFound: false,
      }
    case FETCH_POSTING_SUCCESS: {
      if (action.payload.hits.hits.length > 0) {
        const posting = action.payload.hits.hits[0]._source
        posting['description'] = posting.description.replace(
          /((color|font-size|font-family):.*?(.;|(?="))|(color|size|face)=".*?")/g,
          ''
        )
        return {
          ...state,
          posting: { ...posting },
          postingLoading: false,
          postingNotFound: false,
        }
      } else {
        return {
          ...state,
          postingLoading: false,
          postingNotFound: true,
        }
      }
    }
    case FETCH_POSTING_FAIL:
      return {
        ...state,
        posting: {},
        error: action.payload,
        postingLoading: false,
        postingNotFound: false,
      }
    default:
      return state
  }
}

export function fetchPosting(elasticParameters, elasticIndex) {
  return {
    type: CALLAPI,
    types: [FETCH_POSTING, FETCH_POSTING_SUCCESS, FETCH_POSTING_FAIL],
    callAPI: () => axios.post(elasticSearchURL(elasticIndex), elasticParameters),
    retry: (payload) => {
      return payload.hits.hits.length == 0
    },
  }
}
